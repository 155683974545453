import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Success = props => (
  <Layout bodyClass="page-success">
    <SEO title="Success" />
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Success!</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="col-8">
        <p>Your quote has been submitted. Our team will get back to you as soon as possible.</p>
      </div>
    </div>
  </Layout>
);

export default Success;
